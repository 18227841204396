<template>
  <div class="post-list">
    <div v-for="post in posts" :key="post.id" class="post">
      <img :src="post.image" alt="post image" class="post-image" />
      <p>{{ post.caption }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    posts: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.post-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.post {
  width: 300px;
  margin-bottom: 20px;
}
.post-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}
</style>