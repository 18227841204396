<template>
  <div>
    <h1>Home Feed</h1>
    <p>Welcome to the home feed page!</p>
    <PostList :posts="posts" />
  </div>
</template>

<script>
import PostList from '../components/PostList.vue';
import axiosInstance from '../api/axiosInstance';

export default {
  components: {
    PostList,
  },
  data() {
    return {
      posts: [],
    };
  },
  created() {
    axiosInstance.get('posts/')
      .then(response => {
        this.posts = response.data;
      })
      .catch(error => {
        console.error('Error fetching posts:', error);
      });
  },
};
</script>
