<template>
  <div id="app">
    <nav class="navbar">
      <div class="nav-left">
        <router-link class="nav-btn" to="/">ChinaGO</router-link>
        <router-link class="nav-btn" to="/map">Map</router-link>
        <router-link class="nav-btn" to="/shop">Shop</router-link>
      </div>
      
      <!-- Search Bar in the middle -->
      <div class="nav-search">
        <input type="text" class="search-input" placeholder="Search..." />
        <button class="search-btn">Search</button>
      </div>

      <div class="nav-right">
        <!-- Show Login Button and SignUp Text when not authenticated -->
        <div v-if="!isAuthenticated" class="auth-options">
          <router-link class="nav-btn login-btn" to="/login">Login</router-link>
        </div>

        <!-- Show user profile picture when authenticated -->
        <router-link v-if="isAuthenticated" class="user-profile" to="/user/profile">
          <img :src="profilePictureUrl || defaultProfilePictureUrl" alt="Profile Picture" class="profile-pic">
        </router-link>
        <!-- Show logout button when authenticated -->
        <button v-if="isAuthenticated" @click="logout" class="nav-btn">Logout</button> <!-- Use nav-btn class here -->
      </div>
    </nav>
    
    <div class="home-page-content">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isAuthenticated: !!localStorage.getItem('authToken'), // Check if the user is authenticated
      profilePictureUrl: '', // Initialize profilePictureUrl
      defaultProfilePictureUrl: '' // Initialize defaultProfilePictureUrl
    };
  },
  methods: {
    logout() {
      localStorage.removeItem('authToken'); // Clear the authentication token
      localStorage.removeItem('profilePictureUrl'); // Clear the profile picture URL
      this.isAuthenticated = false; // Update the state
      this.$router.push('/'); // Redirect to the home page
    },
    loadProfilePicture() {
      const storedProfilePictureUrl = localStorage.getItem('profilePictureUrl');
      this.profilePictureUrl = storedProfilePictureUrl ? `${this.getBaseUrl()}${storedProfilePictureUrl}` : this.defaultProfilePictureUrl;
    },
    getBaseUrl() {
      const protocol = process.env.NODE_ENV === 'production' ? 'https:' : 'http:';
      const hostname = window.location.hostname;
      const port = process.env.NODE_ENV === 'production' ? '' : '8000';
      return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    }
  },
  mounted() {
    this.defaultProfilePictureUrl = `${this.getBaseUrl()}/media/profiles/default.jpg`; // Set the default profile picture URL dynamically
    if (this.isAuthenticated) {
      this.loadProfilePicture(); // Load profile picture on mount if authenticated
    }
  },
  watch: {
    '$route'() {
      // Re-evaluate authentication state when the route changes
      this.isAuthenticated = !!localStorage.getItem('authToken');
      if (this.isAuthenticated) {
        this.loadProfilePicture(); // Update profile picture on route change
      }
    }
  }
};
</script>

<style scoped>
/* General Layout */
#app {
  font-family: 'Helvetica', sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

/* Navbar Styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.5rem;
  background-color: #b22222; /* Red Theme */
  color: white;
  flex-shrink: 0;
}

.nav-left, .nav-right, .nav-search {
  display: flex;
  align-items: center;
}

/* Search Bar Styling */
.nav-search {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.search-input {
  padding: 0.5rem;
  border-radius: 25px; /* Sleeker Shape */
  border: 1px solid #ccc;
  margin-right: 10px;
  width: 250px;
  font-size: 1rem;
}

.search-btn {
  padding: 0.5rem 1rem;
  background-color: #b22222; /* Match Navbar Red */
  color: white;
  border: none;
  border-radius: 25px; /* Sleeker Shape */
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s;
}

.search-btn:hover {
  background-color: #ff6666; /* Lighter Red on Hover */
}

/* Button-like Links */
.nav-btn {
  text-decoration: none;
  color: white;
  background-color: #b22222; /* Match Navbar Red */
  padding: 0.5rem 1.5rem;
  border-radius: 25px; /* Sleeker Shape */
  margin-left: 15px;
  font-weight: 500;
  transition: background-color 0.3s, box-shadow 0.2s;
  border: none; /* Remove border for buttons */
  box-shadow: none; /* Remove box-shadow for consistency */
  cursor: pointer; /* Ensure both have a pointer cursor */
}

.nav-btn:hover {
  background-color: #ff6666; /* Lighter Red on Hover */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Sleek shadow effect */
}

/* Auth Options for non-authenticated users */
.auth-options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-text {
  margin: 0.5rem 0 0;
  color: white;
  font-size: 0.9rem;
}

.signup-btn {
  color: #ffcccc;
  text-decoration: none;
  font-weight: bold;
}

.signup-btn:hover {
  color: #fff;
}

/* Profile Picture when authenticated */
.user-profile {
  margin-left: 15px;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid white; /* White border for aesthetics */
}


/* Home Page Content */
.home-page-content {
  flex-grow: 1;
  text-align: center;
  overflow: hidden;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-left, .nav-right {
    margin-bottom: 10px;
  }

  .nav-left a, .nav-right a {
    margin-left: 0;
    margin-right: 15px;
  }

  .nav-search {
    justify-content: flex-start;
    width: 100%;
  }

  .search-input {
    width: 100%;
  }
}
</style>

<style>
/* Global Styles */
html, body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden; /* Prevent scrolling */
  box-sizing: border-box;
}
</style>