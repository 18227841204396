import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import AMapLoader from "@amap/amap-jsapi-loader";

// Load environment variables
fetch('/env.js')
  .then(response => response.text())
  .then(script => {
    const envScript = document.createElement('script');
    envScript.text = script;
    document.head.appendChild(envScript);

    console.log('BASE_URL:', process.env.BASE_URL || '/');

    const app = createApp(App);

    // Set global properties if needed
    app.config.globalProperties.productionTip = false;

    window._AMapSecurityConfig = {
      securityJsCode: 'e64f6ab2b8ae882a8f54180cc3cd9d2f', // Replace with your actual security code
    };

    AMapLoader.load({
      key: '2f4282044819ed820b2e8ed8ce84fa22', // Replace with your actual API key
      version: '2.0',
      plugins: ['AMap.Scale'], // Add more plugins if needed
    })
      .catch((e) => {
        console.error('Failed to load AMap API:', e);
      });

    app.use(router);
    app.mount('#app');
  })
  .catch((e) => {
    console.error('Failed to load environment variables:', e);
  });