<template>
  <div class="profile">
    <h2>Welcome to your profile, {{ username }}!</h2>
    <!-- Display additional user-specific information here -->
  </div>
</template>

<script>
import axiosInstance from '../api/axiosInstance';

export default {
  data() {
    return {
      username: '',
    };
  },
  created() {
    this.fetchUserProfile();
  },
  methods: {
    async fetchUserProfile() {
      try {
        const token = localStorage.getItem('authToken');
        const response = await axiosInstance.get('auth/user/', {
          headers: {
            Authorization: `Token ${token}`
          },
          withCredentials: true  // Make sure to include this if you have CSRF protection enabled
        });
        this.username = response.data.username;
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>