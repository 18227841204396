<template>
    <div>
      <h1>Shop</h1>
      <ul>
        <li v-for="seller in sellers" :key="seller.id">
          {{ seller.name }} - {{ seller.description }}
          <button @click="shop(seller)">Shop Now</button>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        sellers: [],
      };
    },
    created() {
      // Fetch sellers from your API
      this.sellers = [
        { id: 1, name: 'Seller 1', description: 'Best services in town.' },
        { id: 2, name: 'Seller 2', description: 'Affordable and reliable.' },
      ];
    },
    methods: {
      shop(seller) {
        alert(`Shop made for ${seller.name}`);
      },
    },
  };
  </script>