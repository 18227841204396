<template>
  <div id="container"></div>
  <div v-if="activePOI" class="custom-info-window" :style="infoWindowStyle">
    <h4 class="poi-title">{{ activePOI.name }}</h4>
    <p class="poi-type">Type: {{ activePOI.type }}</p>
    <p class="poi-labels">Labels:
      <span v-if="displayedLabels.length > 0">
        <span v-for="label in displayedLabels" :key="label.label?.id" class="label-pill">
          <span v-if="label.label && label.label.name">
            {{ label.label.name }}
            <span class="label-count">{{ label.count }}</span>
          </span>
        </span>
      </span>
      <span v-else>No labels added yet.</span> <!-- Display when no labels exist -->
    </p>
    <div class="button-container">
      <button @click="addLabel" class="info-btn">Add label</button>
      <button @click="viewLabel" class="info-btn">View all labels</button>
      <button @click="closeInfoWindow" class="info-btn close-btn">Close</button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import AMapLoader from "@amap/amap-jsapi-loader";
import axiosInstance from '../api/axiosInstance';

let map = null;
const activePOI = ref(null);
const infoWindowStyle = ref({ top: '60px', left: '10px', width: '350px', height: '300px' });
const viewingLabels = ref(false);

const topThreeLabels = computed(() => {
  if (!activePOI.value || !activePOI.value.labels) return [];
  return [...activePOI.value.labels]  // Create a copy of the array
    .sort((a, b) => b.count - a.count)
    .slice(0, 3);
});

const topTwentyLabels = computed(() => {
  if (!activePOI.value || !activePOI.value.labels) return [];
  return [...activePOI.value.labels]  // Create a copy of the array
    .sort((a, b) => b.count - a.count)
    .slice(0, 20);
});

const displayedLabels = computed(() => {
  return viewingLabels.value ? topTwentyLabels.value : topThreeLabels.value;
});

onMounted(() => {
  AMapLoader.load({
    key: "2f4282044819ed820b2e8ed8ce84fa22",
    version: "2.0",
    plugins: ["AMap.PlaceSearch", "AMap.Geolocation"],
  })
    .then((AMap) => {
      const geolocation = new AMap.Geolocation({
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0,
        convert: true,
        showMarker: true,
        showCircle: false,
        showButton: true,
        panToLocation: true,
        zoomToAccuracy: true,
        buttonOffset: new AMap.Pixel(10, 20),
        buttonPosition: 'RB'
      });

      map = new AMap.Map("container", {
        zoom: 15,
      });

      geolocation.getCurrentPosition((status, result) => {
        if (status === 'complete' && result.position) {
          const placeSearch = new AMap.PlaceSearch({
            pageSize: 5,
            pageIndex: 1,
            extensions: 'all',
          });

          map.on("click", (e) => {
            const { lng, lat } = e.lnglat;

            // Search for nearby POIs at the clicked location
            placeSearch.searchNearBy('', [lng, lat], 1000, async (status, result) => {
              if (status === 'complete' && result.poiList && result.poiList.pois.length > 0) {
                const nearestPOI = result.poiList.pois[0];  // Get the nearest POI
                console.log("Nearest POI:", nearestPOI);

                  // Set the active POI data
                  activePOI.value = {
                    amap_id: nearestPOI.id,
                    name: nearestPOI.name,
                    address: nearestPOI.address,
                    type: nearestPOI.type,
                    location: {
                      lng: lng,
                      lat: lat
                    },
                    labels: ref([])  // Initialize labels as an empty array
                  };
                 const poiExists = await checkPOIExists(nearestPOI.id);
                // Fetch labels from the backend
                if (poiExists) {
                  await fetchLabels(nearestPOI.id);
                } else {
                  console.log("POI does not exist in the backend", nearestPOI.id);
                }
              } else {
                console.error("No POIs found or an error occurred:", result);
              }
            });
          });
        } else {
          console.error('Failed to get current position:', result);
        }
      });
      map.addControl(geolocation);
    })
    .catch((e) => {
      console.error("Failed to load AMap:", e);
    });
});

const checkPOIExists = async (amap_id) => {
  try {
    await axiosInstance.get(`pois/${amap_id}/`);
    console.log("POI exists.");
    return true;
  } catch (error) {
    return false;
  }
};

const fetchLabels = async (amap_id) => {
  try {
    const response = await axiosInstance.get(`pois/${amap_id}/labels/`);
    const labels = response.data;
    console.log("Current labels:", labels);
    activePOI.value.labels = labels.map(label => ({ label: label.label, count: label.count }));
    console.log("Logged labels:", activePOI.value.labels);
  } catch (error) {
    console.error('Failed to fetch labels:', error);
  }
};

const addLabel = async () => {
  console.log("values: ", activePOI.value);
  const label = prompt('Enter new label:');
  if (label) {
    try {
      console.log("activePOI.value.location: ", activePOI.value.location);
      console.log("location sent: ", `POINT(${activePOI.value.location.lng} ${activePOI.value.location.lat})`);
      // Check if the POI already exists, if not createa a new one
      try {
        await axiosInstance.post('pois/create/', {
          amap_id: activePOI.value.amap_id,
          name: activePOI.value.name,
          location: `POINT(${activePOI.value.location.lng} ${activePOI.value.location.lat})`,  // Ensure location is included as a string
          address: activePOI.value.address,
          type: activePOI.value.type,
        });
      } catch (error) {
        if (error.response && error.response.data.amap_id) {
          console.log('POI already exists, skipping creation.');
        } else {
          throw error;  // Re-throw if it's a different error
        }
      }
      // Add the label to the POI
      await axiosInstance.post(`pois/${activePOI.value.amap_id}/labels/`, {
        name: label
      });

      const existingLabel = activePOI.value.labels.find(item => item.label.name === label);

      if (existingLabel) {
        existingLabel.count += 1;
      } else {
        activePOI.value.labels.push({ label: { name: label }, count: 1 });
      }
      
      console.log('Label added successfully and frontend updated.');
    } catch (error) {
      console.error('Failed to add label:', error);
    }
  }
};

const viewLabel = () => {
  viewingLabels.value = !viewingLabels.value;
  if (viewingLabels.value && activePOI.value.labels.length === 0) {
    console.log("No labels added. Go ahead and add a label!");
  }
};

const closeInfoWindow = () => {
  activePOI.value = null;
  viewingLabels.value = false;
};
</script>

<style scoped>
#container {
  padding: 0;
  margin: 0;
  flex-grow: 1;
  width: 100%;
  height: calc(100vh - 60px);
  position: relative;
}

.custom-info-window {
  position: fixed;
  width: 350px;
  height: 300px;
  background-color: hsl(0, 0%, 100%);
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 999;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}

.poi-title {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.poi-address {
  margin: 10px 0;
  font-size: 14px;
  color: #555;
}

.poi-labels {
  font-size: 13px;
  color: #777;
}

.label-pill {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  margin: 5px;
  position: relative;
}

.label-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 12px;
}

.label-list {
  margin-top: 10px;
}

.label-list h5 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.label-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.label-list li {
  font-size: 14px;
  color: #555;
  margin: 5px 0;
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  position: relative;
}

.button-container {
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;
  display: flex;
  justify-content: space-between;
}

.info-btn {
  background-color: #007bff;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.info-btn:hover {
  background-color: #0056b3;
}

.close-btn {
  background-color: #dc3545;
}

.close-btn:hover {
  background-color: #c82333;
}

/* Media Queries for Mobile Devices */
@media (max-width: 600px) {
  .custom-info-window {
    width: 90%;
    height: auto;
    top: 10px;
    left: 5%;
    padding: 10px;
  }

  .poi-title {
    font-size: 16px;
  }

  .poi-type, .poi-labels {
    font-size: 12px;
  }

  .label-pill {
    padding: 3px 8px;
    margin: 3px;
  }

  .label-count {
    font-size: 10px;
    padding: 1px 4px;
  }

  .button-container {
    flex-direction: column;
    align-items: center;
  }

  .info-btn {
    width: 100%;
    margin-bottom: 10px;
  }

  .info-btn:last-child {
    margin-bottom: 0;
  }
}
</style>