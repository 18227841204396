<template>
  <div class="signup">
    <h2>Create an Account</h2>
    <form @submit.prevent="signup">
      <div class="form-group">
        <label for="username">Username:</label>
        <input type="text" v-model="username" id="username" required />
      </div>
      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" v-model="email" id="email" required />
      </div>
      <div class="form-group">
        <label for="password1">Password:</label>
        <input type="password" v-model="password1" id="password1" required />
      </div>
      <div class="form-group">
        <label for="password2">Confirm Password:</label>
        <input type="password" v-model="password2" id="password2" required />
      </div>
      <button type="submit">Sign Up</button>
    </form>
    <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
  </div>
</template>

<script>
import axiosInstance from '../api/axiosInstance';

export default {
  data() {
    return {
      username: '',
      email: '',
      password1: '',
      password2: '',
      errorMessage: '',
    };
  },
  methods: {
    async signup() {
      try {
        await axiosInstance.post('auth/register/', {
          username: this.username,
          email: this.email,
          password1: this.password1,
          password2: this.password2,
        }, {
          withCredentials: true  // Make sure to include this if you have CSRF protection enabled
        });
        alert('Account created successfully!');
        this.$router.push('/login'); // Redirect to login page after signup
      } catch (error) {
        if (error.response && error.response.data) {
          this.errorMessage = error.response.data.detail || 'An error occurred. Please try again.';
        } else {
          this.errorMessage = 'An error occurred. Please try again later.';
        }
      }
    },
  },
};
</script>

<style scoped>
.signup {
  max-width: 400px; /* Slightly smaller width for a more compact form */
  margin: 50px auto; /* Center horizontally */
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center all the form elements */
}

.form-group {
  width: 100%; /* Ensure the label and input take full width */
  margin-bottom: 1.5rem; /* Space between form elements */
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  text-align: left; /* Align labels to the left */
}

input {
  width: 100%; /* Ensure the input takes full width */
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box; /* Include padding and border in element's total width */
}

button {
  width: 100%; /* Button takes full width for consistency */
  padding: 0.75rem;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.1rem;
}

button:hover {
  background-color: #218838;
}

.error {
  color: red;
  margin-top: 1rem;
  font-size: 0.9rem;
}
</style>