import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import MapPage from '../views/MapPage.vue';
import ShopPage from '../views/ShopPage.vue';
import Signup from '../components/SignUp.vue';
import Login from '../components/LogIn.vue';
import UserProfile from '../components/UserProfile.vue';

const routes = [
  { path: '/', component: HomePage },
  { path: '/map', component: MapPage },
  { path: '/shop', component: ShopPage },
  { path: '/signup', name: 'Signup', component: Signup },
  { path: '/login', name: 'Login', component: Login },
  { path: '/user/profile', name: 'UserProfile', component: UserProfile },
];

// Create the router instance
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;